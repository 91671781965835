
.ticket-header-card {
    display: flex;
    flex-direction: column;
    box-shadow: -1px 1px 4px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: -1px 1px 4px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 1px 4px 0px rgba(0,0,0,0.15);
    padding: 10px;
    border-radius: 10px;
}

.ticket-sub {
    display: flex;
    align-items: center;
}

.ticket-sub2 {
    display: flex;
    align-items: center;
    background-color: #FF7F50;
    color: white;
}
.ticket-sub2 div {
    width: 25%;
    text-align: center;
}
.ticket-sub2 h3 {
    color: black;
}
.ticket-sub2 h5 {
    color: white;
}
.ticket-div1 {
    width: 10%;
}
.ticket-div1 h3 {
    text-align: center;
}
.ticket-div1 h5 {
    text-align: center;
    padding: 10px;
    color: white;
    margin-top: -4%;
}
.ticket-div2 {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    padding-left: 2%;
}
.ticket-div2 h2 {
    margin-top: 1%;
}

.ticket-div2 p {
margin-top: -1%;
font-size: 18px;
}
.ticket-div3 {
    width: 10%;
}
.detail-ticket {
    /* box-shadow: -1px 1px 4px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: -1px 1px 4px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 1px 4px 0px rgba(0,0,0,0.15); */
    margin-bottom: 0%;
   border: 1px solid #d9d9d9;
}

.detail-ticket-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 15px 10px 10px;
}

.card-sub {
    display: flex;
    width: 100%;
}
.card-sub div {
    margin-left: 2%;
}
.card-sub h1 {
    margin-top: 3%;
}
.card-sub p {
    font-style: italic;
    color: grey;
}
.time-stamp {
    font-style: italic;
    color: grey;
}
.card-content {
    padding: 15px;
}
.avatar {
    border-radius: 10%;
}
.detail-container {
    margin-top: 0%;
}
.footer {
    padding: 10px;
}