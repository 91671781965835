

.signin-component {
    display: flex;
    height: 100vh;
}

.signins-div1 {
    width: 100%;
}

.signins-div1 img {
    width: 100%;
    height: 100%;
}
.signnin-div2 {
    width: 100%;
    padding: 0% 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fffefe; 
}
.err-msg {
    color: white;
    background-color: rgb(233, 124, 124);
    padding: 10px;
    text-align: center;
    margin-top: 0%;
}
.none {
    display: none;
}


.login-component {
    background: #fffefe; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-component h2 {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #555555;
}

.login-component form {
    background-color: white;
    width: 540px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 3px 23px 5px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 3% 2%;
}

.login-component div {
    width: 100%;
    margin-bottom: 1%;
}

.forget-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forget-btn p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #FF7F50;
    cursor: pointer;
    
}
.forget-btn label {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FF7F50;
    margin-top: -1%;
    align-self: center;

    
}

.login-button {
    width: 100%;
    height: 50px;
    background-color: #FF7F50 !important;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}

.login-button:hover {
    background-color: #0F7173 !important;
}


@media screen and (min-width: 320px) and (max-width: 375px) {
    .login-component form {
        width: 100%;
    }
    .forget-btn p {
        font-size: 12px;
    }
    .forget-btn label {
        font-size: 12px;
    }
    .signins-div1 {
        display: none;
    }
    .login-component img {
        width: 100%;
    }
}


@media screen and (min-width: 376px) and (max-width: 425px) {
    .login-component form {
        width: 100%;
    }
    .forget-btn p {
        font-size: 12px;
    }
    .forget-btn label {
        font-size: 12px;
    }
    .signins-div1 {
        display: none;
    }
    .login-component img {
        width: 100%;
    }
}


@media screen and (min-width: 426px) and (max-width: 600px) {
    .login-component form {
        width: 100%;
    }
    .forget-btn p {
        font-size: 12px;
    }
    .forget-btn label {
        font-size: 12px;
    }
    .signins-div1 {
        display: none;
    }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
    .signins-div1 {
        display: none;
    }
}
