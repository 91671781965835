

.tickets-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tickets-actions-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
}

.tickets-actions-div p {
    margin-top: 2%;
    margin-right: 2%;
    font-weight: bold;
}

.tickets-input {
    width: 200px;
}

.tickets-input:focus {
    outline: none;
}
.pp {
    border: 1px solid rgb(194, 184, 184);
    padding: 10px;
}
.filt {
    width: 120px;
}
@media screen and (min-width: 320px) and (max-width: 769px) {
    .tickets-actions {
        display: flex;
        flex-direction: column;
    }
    .tickets-actions-div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .tickets-input {
        width: 100%;
    }
    .filt {
        width: 100%;
        margin-bottom: 1%;
        margin-top: 1%;
    }
}