

.dashboard h1 {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}
.dashboard-overview {
    display: flex;
    flex-direction: column;
}

.stat-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.stat-div p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.stat-div select {
    border: none;
    width: 160px;
    padding: 10px;
    background: #FFFFFF;
    border-radius: 10px;
}
.rider {
    margin-top: -4%;
    /* position: relative; */
    background-image: url("https://res.cloudinary.com/doouwbecx/image/upload/v1624015971/movebot/Group_452_dvwquv.png");
    background-size: cover;
    height: 100%;

}
.rider img {
    width: 100%;
    height: 100%;
    border-radius: 20%;
}

.rider ol {
    /* position: absolute;
    width: 50%;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
    top: 39%;
    left: 45%; */
    padding: 8% 0% 5% 0%;
    display: flex;
    flex-direction: column;
    float: right;
    width: 600px;
    /* justify-content: flex-start;
    align-items: flex-end; */
}

.rider ol li {
    font-size: 18px;
    color: white;
}

.grid-layout {
    margin-top: 2%;
}
.container-box {
    height: 108px;
    padding: 10px 20px;
}

.container-box p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #555555;
}
.container-box div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -8%;
}

.container-box img {
    background-color: #FFECC8;
    padding: 10px;
    border-radius: 50%;
}

.dashboard-row {
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
    margin-bottom: 5%;
}
.dashboard-row-div1 {
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 5%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dashboard-row-div2 {
    width: 50%;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 25px;
}
.dashboard-row-div1-mini {
    display: flex;
    justify-content: space-between;
    padding: 5px 25px;
}
.dashboard-row-div1-mini p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.dashboard-row-div2-mini {
    display: flex;
    flex-direction: column;
}

.dashboard-row-div2-mini div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
}
.dashboard-row-div2-mini p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;  
}

.first-child {
    color: black !important;
    font-weight: bold !important;
    line-height: 18px !important;
    width: 200px;
}

.dashboard-row-div2 img {
    width: 30px;
    height: 30px;
}

@media screen and (min-width: 320px) and (max-width: 375px) {
    .rider {
        display: flex;
        flex-direction: column;
    }
    .dashboard {
        padding: 0% 20px;
    }
    .rider img {
        width: 100%;
        height: 200px;
    }
    .rider p {
        color: black;
        width: 100%;
        position: relative;
        top: 0%;
        left: 0%;
    }
    .dashboard-row {
        display: flex;
        flex-direction: column;
    }
    .dashboard-row-div1 {
        width: 100%;
    }
    .dashboard-row-div2 {
        width: 100%;
    }
}