
.settings {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-gap: 15px;
}

.settings-actions {
    cursor: pointer;
}

.settings-card {
    width: 350px;
}
.support {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.support-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.support-contact div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.support-contact p {
  margin-top: 10%;
}
.support-contact-icon {
  cursor: pointer;
}
.support-team {
  height: 150px;
  overflow-x: scroll;
}
.support-team::-webkit-scrollbar {
  display: none;
}
.half-width {
  width: 49%
}