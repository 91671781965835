.App {
  text-align: center;
}
body {
  background: #f3efef;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.employee-container {
  width: 100%;
}
.filtd {
  width: 100%;
}
#but {
  background-color: #ff7f50;
  border-color: #ff7f50;
}
.ant-card {
  width: 100% !important;
}
.bread-crumb {
  font-size: 18px;
}
#but:hover {
  background-color: rgb(15, 113, 115);
  border-color: rgb(15, 113, 115);
}
.ag-row {
  white-space: wrap !important;
}
.MuiAppBar-colorPrimary {
  background-color: #ff7f50 !important;
  width: 100%;
}
.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  background-color: #ff7f50 !important;
  color: white;
}
.ag-theme-alpine .ag-root-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ff7f50 !important;
  color: white;
}
.ant-table-pagination-right {
  justify-content: flex-start !important;
  position: fixed !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #ff7f50 !important;
}
.PrivateTabIndicator-colorPrimary-4 {
  background-color: #ff7f50 !important;
}

th.ant-table-cell {
  font-weight: bold !important;
}

/* .ant-table::-webkit-scrollbar {
  display: none;
} */

.WithStyles\(ForwardRef\(TableCell\)\)-body-24 {
  text-align: left !important;
}

.MuiTableContainer-root {
  height: 500px;
}
.MuiTableContainer-root::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .MuiDrawer-paper {
    width: 60% !important;
    background: #0f7173 !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .MuiDrawer-paper {
    width: 40% !important;
    background: #0f7173 !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .MuiTab-wrapper {
    font-size: 10px;
  }
}
