

.profile-container {
    background-color: white;
    width: 100%;
    margin-top: 0%;
    padding-bottom: 3%;
}
.ant-descriptions-item-label {
    font-weight: bold;
}
.profile-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1% 0%;
    margin-bottom: -4%;
}

.profile-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.edit-qualify {
    background-color: #FF7F50;
    width: 30px;
    height: 30px;
    color: white;
    cursor: pointer;
}
.admin-select {
    width: 100%;
    padding: 15px;
}

.update-profile {
    padding: 10px;
    margin-right: 3%;
    background-color: #FF7F50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.profile-section img {
    border-radius: 10%;
}
.profile-image-actions {
    margin-top: 1%;
    margin-bottom: 3%;
    width: 100px;
    display: flex;
    justify-content: space-around;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
  }
  
  .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
  }
  .profile-emp-modal {
    width: 700px;
    background-color: white;
    max-height: 95vh;
    padding: 10px;
    border-radius: 10px;
}
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

.profile-div span {
    margin-left: 4%;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    width: 70%;
    display: flex;
    text-align: start;
}
.pld {
    width: 10px;
}
.profile-div h1 {
    font-size: 20px;
}
.delete-textarea {
    width: 100%;
    margin-bottom: 3%;
}
.profile-div h3 {
    font-size: 14px;
    color: rgb(88, 79, 79);
    width: 20%;
    display: flex;
    text-align: start;
}

.employee-form-div {
    display: flex;
    justify-content: space-between;
}


.employee-inputs {
    width: 330px;
    margin-bottom: 2%;
}

.employee-input {
    width: 100%;
    margin-bottom: 2%;
}
.employee-select-role {
    width: 100%;
    padding: 15px;
}
.employee-multi-select {
    margin-top: 3%;
}
.select-field {
    height: 55px;
    margin-right: 5px;
}
.employee-select {
    padding: 10px;
}
.country {
    padding: 14px;
    margin-bottom: 2%;
    width: 100%;
}
.row-select {
    padding: 14px;
    width: 330px;
    margin-bottom: 2%;
}
.add-employee {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
    margin: 4% 0%;
    text-align: center;
}

.signup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4%;
}

.signup-button button {
    width: 200px;
    padding: 10px;
    background-color: coral;
    font-size: 12px;
    margin-top: 5%;
    border: none;
    cursor: pointer;
}

.signup-button button:hover {
    background-color: #0F7173;
}


@media screen and (min-width: 320px) and (max-width: 768px) {
    .select-field {
        margin-bottom: 2%;
    }
    .profile-emp-modal {
        width: 90%;
        height: 90%;
        overflow: scroll;
    }
    .employee-form-div {
        display: flex;
        flex-direction: column;
    }
    .employee-inputs {
        width: 100%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    .profile-emp-modal {
        height: 90%;
        overflow: scroll;
    }
}