
.employee-form-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
}


.employee-inputs {
    width: 330px;
    margin-bottom: 2%;
}

.employee-input {
    width: 100%;
    margin-bottom: 2%;
}
.employee-select-role {
    width: 100%;
    padding: 15px;
}
.searchWrapper {
    padding: 15px !important;
    margin-bottom: 3%;
}
.employee-multi-select {
    margin-top: 3%;
}
.select-field {
    height: 55px;
    margin-right: 5px;
}
.employee-select {
    padding: 10px;
}
.country {
    padding: 14px;
    margin-bottom: 2%;
    width: 100%;
}
.row-select {
    padding: 14px;
    width: 330px;
    margin-bottom: 2%;
}
.add-employee {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: #000000;
    margin: 4% 0%;
    text-align: center;
}

.signup-button {
    display: flex;
    justify-content: center;
    align-items: center;

}

.signup-button button {
    width: 200px;
    padding: 10px;
    background-color: coral;
    font-size: 12px;
    margin-top: 5%;
    border: none;
}

.signup-button button:hover {
    background-color: #0F7173;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .select-field {
        margin-bottom: 2%;
    }
    .emp-modal {
        width: 90%;
        height: 90%;
        overflow: scroll;
    }
    .employee-form-div {
        display: flex;
        flex-direction: column;
    }
    .employee-inputs {
        width: 100%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    .emp-modal {
        height: 90%;
        overflow: scroll;
    }
}