


.employee-container h1 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    font-size: 0.84rem;
    color: #3f51b5;
    text-align: center;
    text-decoration: underline;
}

.employee-actions {
   display: flex;
   justify-content: space-between;
   margin-bottom: 1%;
}
.employee-actions div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 700px; */
}

#search-input {
    width: 600px;
    padding: 14px;
    border: 1px solid #FF7F50;
    border-radius: 5px;
    outline: none;
}
.employee-btn1 {
    width: 200px;
    font-size: 15px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: white;
    background-color: #FF7F50;
    margin: 0% 5%;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.employee-btn2 {
    width: 300px;
    font-size: 15px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: white;
    background-color: #FF7F50;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.emp-modal {
    width: 700px;
    background-color: white;
    height: 90vh;
    padding: 10px;
    border-radius: 10px;
}



@media screen and (min-width: 320px) and (max-width: 768px) {
    .employee-actions div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
    #search-input {
        width: 100%;
    }
    .employee-actions div button {
        margin-top: 2%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    #search-input {
        width: 50%;
    }
}