

.signup-component {
    display: flex;
    height: 100vh;
}

.signup-div1 {
    width: 100%;
}
.signup-div1 img {
    width: 100%;
    height: 100%;
}

.signup-div2 {
    width: 100%;
    padding: 0% 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.signup-div2 h1 {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #555555;
}

.signup-div2 form {
    width: 700px;
}

.signup-form-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
}
.signup-form-div input {
    width: 310px;
}
.signup-form-div2 {
    width: 100%;
    margin-bottom: 2%;
}
.signup-form-div2 input {
    width: 670px;
}

.signup-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-button button {
    width: 80%;
    padding: 12px;
    background: #FF7F50;
    margin-top: 2%;
    border-radius: 2px;
}

.already-acc {
    margin-top: 5%;
}
.already-acc p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

.already-acc span {
    color: #FF7F50;
    cursor: pointer;
}






@media screen and (min-width: 320px) and (max-width: 375px) {
    .signup-component  {
        height: 100%;
    }
    .signup-div1 {
        display: none;
    }
    .signup-div2 {
        padding: 0%;
    }
    .signup-div2 form {
        width: 100%;
    }
    .signup-form-div input {
        width: 280px;
    }
    .signup-form-div2 input {
        width: 280px
    }
    .signup-form-div {
        display: flex;
        flex-direction: column;
    }
    .MuiFormControl-root {
        margin-bottom: 4% !important;
    }
}


@media screen and (min-width: 376px) and (max-width: 425px) {
    .signup-div1 {
        display: none;
    }
    .signup-component  {
        height: 100%;
    }
    .signup-div2 {
        padding: 0%;
    }
    .signup-div2 form {
        width: 100%;
    }
    .signup-form-div input {
        width: 280px;
    }
    .signup-form-div2 input {
        width: 280px
    }
    .signup-form-div {
        display: flex;
        flex-direction: column;
    }
    .MuiFormControl-root {
        margin-bottom: 4% !important;
    }
}


@media screen and (min-width: 426px) and (max-width: 600px) {
    .signup-component  {
        height: 100%;
    }
    .signup-div1 {
        display: none;
    }
    .signup-div2 {
        padding: 0%;
    }
    .signup-div2 form {
        width: 100%;
    }
    .signup-form-div input {
        width: 280px;
    }
    .signup-form-div2 input {
        width: 280px
    }
    .signup-form-div {
        display: flex;
        flex-direction: column;
    }
    .MuiFormControl-root {
        margin-bottom: 4% !important;
    }
}


@media screen and (min-width: 601px) and (max-width: 764px) {
    .signup-div1 {
        display: none;
    }
    .signup-div2 form {
        width: 600px;
    }
    .signup-form-div {
        width: 580px;
    }
    .signup-form-div input {
        width: 250px;
    }
    .signup-form-div2{
        width: 580px
    }
    .signup-form-div2 input {
        width: 550px
    }
}


@media screen and (min-width: 765px) and (max-width: 991px) {
    .signup-div1 {
        display: none;
    }
}


@media screen and (min-width: 992px) and (max-width: 1024px) {
    
}