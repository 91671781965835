
.policy-container h1 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}

.formcontrol-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select-rows {
    display: flex;
    align-items: center;
    padding-left: 2%;
}

.select-rows label {
    font-size: 15px;
    width: 120px;
}
.select-rows select {
    padding: 6px;
}
.paginate {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pag-li {
    max-width: 50px;
    /* height: 30px; */
    list-style-type: none;
    padding: 5px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    background-color: rgb(245, 223, 223);
    margin-left: 1%;
}

.li-active {
    background-color:#FF7F50 !important;
}
.actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
}

.actions p {
    display: flex;
    justify-self: center;
    align-self: flex-start;
    font-size: 13px;
}
.actions span {
    margin-left: 5%;
}
.policy-filter {
    display: flex;
    flex-direction: column-reverse;
    padding: 0% 2%;
}
.filter-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.delete-textarea {
    width: 100%;
}
.filter-sort {
    display: flex;
    justify-content: space-between;
    width: 260px;
}
.filter {
    padding: 10px;
}
.live {
    color: green;
}
.activate {
    width: 60px;
    padding: 10px;
    background-color: green;
    color: white;
    border: none;
    font-size: 11px;
    border-radius: 3px;
    cursor: pointer;
}
.delete {
    width: 70px;
    padding: 10px;
    background-color: red;
    color: white;
    border: none;
    font-size: 11px;
    border-radius: 3px;
    cursor: pointer;
}
.deactivate {
    width: 70px;
    padding: 10px;
    background-color: brown;
    color: white;
    font-size: 11px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.edit {
    width: 70px;
    padding: 10px;
    background-color: blue;
    font-size: 11px;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.detail {
    width: 70px;
    padding: 10px;
    background-color: rgb(68, 198, 221);
    font-size: 11px;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.btn-arr {
    display: flex;
    justify-content: space-between;
}
.row-form {
    width: 480px;
}
.policy-actions {
   display: flex;
   justify-content: flex-end;
   margin-bottom: 3%;
}
#policy-input {
    width: 400px;
    padding: 14px;
    border: none;
}
.policy-actions div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 700px;
}

.policy-btn2 {
    width: 250px;
    font-size: 15px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: white;
    background-color: #FF7F50;
    padding: 10px;
    border: none;
    cursor: pointer;
}
.popin {
    padding: 10px;
}
.employee-update-form {
    display: flex;
    flex-direction: column;
}
.employee-edit-select {
    width: 325px;
    padding: 15px;
    margin-bottom: 3%;
}
.show-reason {
    margin-bottom: 2%;
}
.employee-country-select {
    padding: 15px;
    margin-bottom: 3%;
    width: 100%;
}
.employee-codes {
    height: 55px;
    width: 100px;
    margin-right: 2%;
}

.employee-update-form .employee-input {
    padding: 12px;
}
.policy-modal {
    width: 700px;
    background-color: white;
    max-height: 100vh;
    padding: 10px;
    border-radius: 10px;
    overflow: scroll;
}
.policy-modal::-webkit-scrollbar {
    display: none;
}
.pop-over {
    display: flex;
    flex-direction: column;
}
.policy-det-container {
    margin-bottom: 2%;
}

.policy-det-container h1 {
    font-size: 15px;
    color: #FF7F50;
    padding: 10px;
}
.policy-det-container span {
    color: black;
}
.policy-det-container h1:nth-child(odd) {
    background: rgb(243, 218, 218);
  }
.policy-det-container h1:nth-child(even) {
    background: rgb(227, 245, 227);
  }
.policy-det-container span {
    font-size: 12px;
}

@media screen and (min-width: 320px) and (max-width: 375px) {
    .policy-filter {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .formcontrol-container {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        align-items: flex-start;
        margin-top: 2%;
    }
    .pag-li {
        font-size: 10px;
    }

}


@media screen and (min-width: 320px) and (max-width: 768px) {
    .policy-modal {
        width: 90%;
    }
}





