

.policy-box {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.role-active {
    background-color: coral !important;
    color: white !important;
}
.role-active h3 {
    color: white !important;
}

.left-align {
    text-align: left !important;
}

.left-align-header {
    align-self: center;
    color: white;
    text-align: left;
    cursor: pointer;
}

