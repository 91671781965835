

.policy-form {
    display: flex;
    flex-direction: column;
}

.form-textarea {
    margin: 2% 0% !important;

}

.policy-select-role {
    width: 100%;
    padding: 15px;
}
.searchWrapper {
    padding: 15px !important;
    margin-bottom: 3%;
}
.form-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.policy-multi-select {
    margin-top: 3%;
}
