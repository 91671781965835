
.forget {
    display: flex;
    height: 100vh;
}
.fts-div1 {
    width: 100%;
}
.fts-div1 img {
    width: 100%;
    height: 100%;
}
.forget-p {
    width: 100%;
    padding: 0% 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fffefe; 
}

.forget-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #fffefe; 
}

.forget-password h1 {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #555555;
    text-align: center;
    margin-top: -5%;
}

.forget-password form {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 540px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 3px 23px 5px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 3% 2%;
}

.reset-div {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
}
.reset-div label {
    margin-bottom: 2%;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #555555;
    text-align: left;
}
.verification {
    display: flex;
    justify-content: space-between;
    margin-top: -2%;
    padding: 0% 2%;
    margin-bottom: 2%;
}
.verification p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}
.verification p:last-child {
    cursor: pointer;
}
.forget-btn button:hover {
    background-color: #0F7173 !important;
}
.forget-btn {
    width: 100%;
}
.forget-btn button {
    width: 100%;
    padding: 10px;
    background: #FF7F50;
    border-radius: 2px;
}

.forget-msg {
    color: white;
    background-color: rgb(233, 124, 124);
    padding: 10px;
    text-align: center;
    margin-top: 10%;
    font-size: 10px;
    width: 100%;
}

.forget-success {
    color: white;
    background-color: green;
    padding: 10px;
    text-align: center;
    margin-top: 7%;
    font-size: 10px;
    width: 100%;
}


@media screen and (min-width: 320px) and (max-width: 375px) {
    .forget-password form {
        width: 300px;
    }
    .fts-div1 {
        display: none;
    }
    .forget-msg {
        width: 100%;
        margin-top: 17%;
    }

    .forget-success {
        width: 100%;
        margin-top: 17%;
    }
}


@media screen and (min-width: 376px) and (max-width: 425px) {
    .forget-password form {
        width: 300px;
    }
    .fts-div1 {
        display: none;
    }
    .forget-password form {
        width: 350px;
    }
    .forget-msg {
        width: 100%;
        margin-top: 18%;
    }
    .forget-success {
        width: 100%;
        margin-top: 18%;
    }
}


@media screen and (min-width: 426px) and (max-width: 600px) {
    .forget-password form {
        width: 400px;
    }
    .fts-div1 {
        display: none;
    }
    .forget-msg {
        width: 100%;
        margin-top: 18%;
    }

    .forget-success {
        width: 100%;
        margin-top: 18%;
    }
}


@media screen and (min-width: 601px) and (max-width: 800px) {
    .forget-password form {
        width: 500px;
    }
    .fts-div1 {
        display: none;
    }
    .forget-msg {
        width: 100%;
        margin-top: 10%;
    }
    .forget-success {
        width: 100%;
        margin-top: 10%;
    }
}


@media screen and (min-width: 765px) and (max-width: 991px) {
    
}


@media screen and (min-width: 801px) and (max-width: 1440px) {
    .forget-msg {
        width: 100%;
        margin-top: 18%;
    }
    .forget-success {
        width: 100%;
        margin-top: 18%;
    }
}