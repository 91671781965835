.layout-overview {
  display: flex;
}

.layout-content {
  width: 100%;
  max-height: 1500px;
  padding-left: 20%;
  padding-right: 2%;
  padding-bottom: 2%;
  background: #f3efef;
  overflow-x: hidden;
}
.children-layout {
  margin-top: 2%;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .layout-overview {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .layout-content {
    padding-left: 1%;
    padding-right: 1%;
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .layout-content {
    padding-left: 20%;
    padding-right: 2%;
  }
  .layout-overview {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

/* @media screen and (min-width: 769px) and (max-width: 1024px) {
    .layout-content {
        padding-left: 30%;
        padding-right: 2%;
    }
} */

@media screen and (min-width: 320px) and (max-width: 768px) {
  .layout-content {
    padding-left: 0%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .layout-content {
    padding-left: 20%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .layout-content {
    padding-left: 30%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .layout-content {
    padding-left: 25%;
  }
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
/* [data-theme="dark"] .site-layout-sub-header-background {
      background: #141414;
    } */

.logos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logos div {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.logos img {
  border-radius: 50%;
}

.logos p {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-self: center;
}
.logos span {
  padding-top: 2%;
}

.content {
  margin: 10px 16px 0px 16px;
  height: 90vh;
  overflow: scroll;
}

.content::-webkit-scrollbar {
  display: none;
}
.notification {
  width: 350px;
  max-height: 400px;
  padding: 10px;
  overflow-y: scroll;
}
/* .notification::-webkit-scrollbar {
        display: none;
    } */

@media screen and (min-width: 320px) and (max-width: 500px) {
  .logos div {
    width: 100%;
    margin-left: 15%;
  }
  .tit {
    font-size: 15px !important;
    margin-left: -5%;
  }
  .logos {
    width: 100%;
  }
  .logos span {
    font-size: 10px;
  }
  .ant-layout-header {
    line-height: 30px;

    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
  }
  .logos img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 501px) and (max-width: 895px) {
  .logos div {
    width: 40%;
    margin-left: 15%;
    display: flex;
    justify-content: space-between;
  }
  .tit {
    font-size: 15px !important;
    margin-left: -5%;
  }
  .logos {
    width: 100%;
  }
  .logos span {
    font-size: 10px;
  }
  .ant-layout-header {
    line-height: 30px;

    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
  }
  .logos img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
  .logos div {
    width: 30%;
    margin-left: 15%;
    display: flex;
    justify-content: space-between;
  }
  .tit {
    font-size: 15px !important;
    margin-left: -5%;
  }
  .logos {
    width: 100%;
  }
  .logos span {
    font-size: 10px;
  }
  .ant-layout-header {
    line-height: 30px;

    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
  }
  .logos img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
  .notification {
    width: 100%;
  }
}
