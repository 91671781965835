body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
#root {
  height: 100%;
}

a {
  color: rgba(255, 127, 80, 1);
}

.ant-tag {
  border-radius: 12px !important;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-steps-item-icon {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
